define([
        'modules/upx/models/model',
        './structs/ShopProduct'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'ShopProduct',
            setActiveStatus: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setShopProductsActiveStatus',parameters, ajaxOptions);
            },
            cloneSimpleIntoConfigurable: function (parameters, ajaxOptions) {
                return this.callObjectMethod('cloneSimpleShopProductIntoConfigurable',parameters, ajaxOptions);
            },
            findProbableConfigurable: function (parameters, ajaxOptions) {
                return this.callObjectMethod('findProbableConfigurableShopProducts',parameters, ajaxOptions);
            },
            assign: function (parameters, ajaxOptions) {
                return this.callObjectMethod('assignShopProduct',parameters, ajaxOptions);
            },
            deassign: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deassignShopProduct',parameters, ajaxOptions);
            },
            isNeededForFullfillment: function (parameters, ajaxOptions) {
                return this.callObjectMethod('isShopProductNeededForFullfillment',parameters, ajaxOptions);
            }
        });
    }
);