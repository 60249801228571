define([
        'modules/upx/models/model',
        './structs/ShopRelation'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'ShopRelation',
            registerShopCustomer: function (parameters, ajaxOptions) {
                return this.callObjectMethod('registerShopCustomer',parameters, ajaxOptions);
            },
            sendShopCustomerWelcomeEmail: function (parameters, ajaxOptions) {
                return this.callObjectMethod('sendShopCustomerWelcomeEmail',parameters, ajaxOptions);
            },
            findShopCustomerBySubuserEmail: function (parameters, ajaxOptions) {
                return this.callObjectMethod('findShopCustomerBySubuserEmail',parameters, ajaxOptions);
            },
            newShopCustomer: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newShopCustomer',parameters, ajaxOptions);
            },
            newShopSupplier: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newShopSupplier',parameters, ajaxOptions);
            }
        });
    }
);