define([
        'modules/upx/models/model',
        './structs/LoyaltyCustomer'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'LoyaltyCustomer'
        });
    }
);