define([
        'modules/upx/collections/collection',
        '../models/RepairQuestionAnswer',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ShopModule',
            object: 'RepairQuestionAnswer',
            collection_method: 'listRepairQuestionAnswers',
            model: Model
        });
    }
);