define([
        'modules/upx/models/model',
        './structs/CustomerPermanentDiscount'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'relation_data_id',
            object: 'CustomerPermanentDiscount',
            setCustomerPermanentDiscount: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setCustomerPermanentDiscount',parameters, ajaxOptions);
            }
        });
    }
);