define([
        'modules/upx/models/model',
        './structs/MemberCard'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'MemberCard',
            setMemberCard: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setMemberCard',parameters, ajaxOptions);
            },
            find: function (parameters, ajaxOptions) {
                return this.callObjectMethod('findMemberCard',parameters, ajaxOptions);
            },
            deactivateOverdue: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deactivateOverdueMemberCards',parameters, ajaxOptions);
            }
        });
    }
);