define([
        'modules/upx/models/model',
        './structs/OrderLog'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'OrderLog'
        });
    }
);