define([
        'modules/upx/models/model',
        './structs/OrderShipment'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'shipment_id',
            object: 'OrderShipment',
            preview: function (parameters, ajaxOptions) {
                return this.callObjectMethod('previewOrderShipment',parameters, ajaxOptions);
            },
            newOrderItemsWithPickUpInStore: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newOrderItemsWithPickUpInStoreOrderShipment',parameters, ajaxOptions);
            },
            newPickUpInStore: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newPickUpInStoreOrderShipment',parameters, ajaxOptions);
            }
        });
    }
);