define([
        'modules/upx/models/model',
        './structs/OrderInvoice'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'OrderInvoice',
            getIds: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getOrderInvoiceIds',parameters, ajaxOptions);
            },
            pay: function (parameters, ajaxOptions) {
                return this.callObjectMethod('payOrderInvoice',parameters, ajaxOptions);
            },
            payOrderInvoices: function (parameters, ajaxOptions) {
                return this.callObjectMethod('payOrderInvoices',parameters, ajaxOptions);
            }
        });
    }
);